<template>
    <div class="">
      <div class="flex items-center justify-between pt-1">
        <div class="heading-3 text-text1">● Attachments (<span>{{totalCount}}</span>):</div>
        <div class=" text-text1 flex items-center">
          <div class="flex items-center" v-for="(data, ind) in array" :key="ind">
            <div class="px-2 mx-1 py-1 border cursor-pointer  rounded-xl" :class="data.isSel ? 'border-primary text-primary' : 'border-error text-error line-through'" @click="filterListing(data)">{{data.title}}</div>
          </div>
          <p @click="isCardView=false" :class="isCardView ? 'text-text2' : 'text-primary'" class="cursor-pointer heading-1 pl-4"><i class="fa-solid fa-list "></i></p>
          <span @click="isCardView=true" :class="!isCardView ? 'text-text2' : 'text-primary'" class="pl-4 cursor-pointer heading-1"><i class="fa-solid fa-grip-vertical "></i></span>
        </div>
      </div>
      <div v-if="organizationList.length !== 0">
        <div v-if="isCardView" class="sm:grid  text-gray3 mb-4 card rounded-xl bg-white p-4" :class="isManualSelect ? 'justify-between grid-cols-1' : 'justify-end grid-cols-1'">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-between">
              <div class="cursor-pointer text-text2 pr-4 font-semibold" @click="toggleSel()" v-if="tempSelArr.length === 0">
                <!-- <span v-if="isManualSelect">Cancel</span>
                <span v-if="!isManualSelect"><i class="fas fa-trash-alt text-error"></i></span> -->
                <span v-if="isManualSelect">Cancel</span>
                <span v-if="!isManualSelect" class="text-primary">Delete Attachment</span>
                <!-- {{isManualSelect ? 'Cancel' : 'Delete Attachment'}} -->
              </div>
              <div class=" text-white cursor-pointer bg-error px-2.5 py-1 rounded-xl mr-2" @click="removeConfirm=true" v-show="tempSelArr.length > 0">
                <span><i class="fas fa-trash-alt"></i></span>
              </div>
              <div v-if="isManualSelect" class="flex items-center justify-between">
                <div v-if="isManualSelect && tempSelArr.length > 0" class=" text-primary cursor-pointer font-semibold pr-4" @click="clearSelection()">Clear Selection</div>
                <div class="flex items-center">
                  <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck()" v-model="isSelectAll" >
                  <span class="pl-2 cursor-pointer font-semibold" @click="checkboxCheck()">{{isSelectAll ? 'Deselect All' : 'Select All'}}</span>
                </div>
              </div>
            </div>
            <div :class="mobileView ? '' : 'text-right flex justify-end font-semibold'" >
              <div class="" v-if="tempSelArr.length === 0">Showing {{filterObject.start + 1}}-<span>{{totalCount > 100 ? filterObject.start + organizationList.length : totalCount}}</span> of {{ totalCount }}</div>
              <div class="" v-else>Selected {{tempSelArr.length}} of {{ totalCount }}</div>
            </div>
          </div>
        </div>
        <div v-if="!isCardView" class="sm:grid  text-gray3 mb-4 card rounded-xl bg-white p-4" :class="isManualSelect ? 'justify-between grid-cols-1' : 'justify-end grid-cols-1'">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-between">
              <div class="cursor-pointer text-text2 pr-4 font-semibold" @click="toggleSel()" v-if="tempSelArr.length === 0">
                <span v-if="isManualSelect">Cancel</span>
                <span v-if="!isManualSelect" class="text-primary">Delete Attachment</span>
              </div>
              <div class=" text-white cursor-pointer bg-error px-2.5 py-1 rounded-xl mr-2" @click="removeConfirm=true" v-show="tempSelArr.length > 0">
                <span><i class="fas fa-trash-alt"></i></span>
              </div>
              <div v-if="isManualSelect" class="flex items-center justify-between">
                <div v-if="isManualSelect && tempSelArr.length > 0" class=" text-primary cursor-pointer font-semibold pr-4" @click="clearSelection()">Clear Selection</div>
                <div class="flex items-center">
                  <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck()" v-model="isSelectAll" >
                  <span class="pl-2 cursor-pointer font-semibold" @click="checkboxCheck()">{{isSelectAll ? 'Deselect All' : 'Select All'}}</span>
                </div>
              </div>
            </div>
            <div :class="mobileView ? '' : 'text-right flex justify-end font-semibold'" >
              <div class="" v-if="tempSelArr.length === 0">Showing {{filterObject.start + 1}}-<span>{{totalCount > 100 ? filterObject.start + organizationList.length : totalCount}}</span> of {{ totalCount }}</div>
              <div class="" v-else>Selected {{tempSelArr.length}} of {{ totalCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_space rounded h-full bg-white">
        <div>
        <div class="w-full">
          <div class="progress-bar" v-if="searchLoader">
            <div class="progress-bar-value"></div>
          </div>
        </div>
        <div style="overflow-y: auto; white-space: nowrap;" class="table_containder" v-if="!isCardView && organizationList.length > 0">
          <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 p-1 items-center">
            <div class="name_col flex items-center p-2">
              <span class="">Name</span>
            </div>
            <div class="name_col1  flex items-center p-2" v-if="filterObject.orgId === 0">
                <span class="cursor-pointer" :class="titleObj[2].isSel ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
                <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
                <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
            <div class="name_col1 flex items-center p-2">
              <span class="">File Type</span>
            </div>
            <div class="name_col1  flex items-center p-2">
              <span class="cursor-pointer" :class="titleObj[0].isSel ? 'text-primary' : ''" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
              <img class="pl-1" v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img class="pl-1" v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
            <div class="name_col1  flex items-center p-2">
              <span class="cursor-pointer" :class="titleObj[1].isSel ? 'text-primary' : ''"  @click="sortByColumn(1)">{{titleObj[1].title}}</span>
              <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
              <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
            </div>
          </div>
          <div class="overflow-y-auto overflow-x-hidden h-full" :style="`max-height:` + cardHeight2 + `px;`">
            <div v-for="(data, index) in organizationList" :key="index" class="min-w-min group flex border-r border-l border-b border-gray1  bg-white  text-sm p-1 items-center cursor-pointer hover:bg-gray-50" @click="showFullImage(data, index)">
              <div class="name_col p-2 items-center flex">
                <!-- <div><input type="checkbox" /></div> -->
                <input v-if="isManualSelect" type="checkbox" class="h-4 w-4 cursor-pointer text-primary mr-2" v-model="data.isSelected" >
                <span class="heading-5 overFlowParaA text-text2" v-if="data.displayFileName !== ''">{{data.displayFileName}}</span>
                <span class="" v-else>--</span>
              </div>
              <div class="name_col1 p-2" v-if="filterObject.orgId === 0">
                <span class="heading-5 text-text2" v-if="data.orgName !== ''">{{data.orgName}}<span class="heading-6 text-gray4 pl-1">({{data.orgCode}})</span></span>
                <span class="" v-else>--</span>
              </div>
              <div class="name_col1 p-2">
                <span class="heading-5 text-text2" v-if="data.fileType !== ''">{{data.fileType}}</span>
                <span class="" v-else>--</span>
              </div>
              <div class="name_col1 p-2">
                <span class="heading-5 text-text2" v-if="data.fileSize !== ''">{{data.fileSize | getFileSize}}</span>
                <span class="" v-else>--</span>
              </div>
              <div class="name_col1 p-2">
                <span class="heading-5 text-text2" v-if="data.uploadedOn !== ''">{{data.uploadedOn | dateTimeWithName}}</span>
                <span class="" v-else>--</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCardView && organizationList.length > 0">
            <div class=" text-gray3 mb-4 card rounded-xl bg-white p-4 my-4 overflow-y-auto overflow-x-hidden h-full" :style="`max-height:` + cardHeight + `px;`">
                    <div class="flex gap-5" style="flex-wrap: wrap;">
                    <div v-for="(attach, a) in organizationList" :key="a" @click="showFullImage(attach, a)" class="card rounded-xl bg-white " :class="attach.isSelected ? 'border border-error' : 'border border-gray-100'">
                      <div class=" attachment-card cursor-pointer group" style="width: 155px; height: 95px">
                        <div v-if="attach.docType === 'image'" class="text-center">
                          <img style="height: 80px;width: 140px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                          <div v-if="a + 1 !== organizationList.length" class="invisible  absolute -bottom-14 bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.displayFileName }}</div>
                        </div>
                        <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                            <img style="height: 80px;width: 140px;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                            <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                            <div v-if="a + 1 !== organizationList.length" class="invisible  w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                        </div>
                        <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                            <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                            <div v-if="a + 1 !== organizationList.length" class="invisible  w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                        </div>
                        <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                            <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                            <div v-if="a + 1 !== organizationList.length" class="invisible  w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                        </div>
                        <div v-if="attach.isSelected" class="absolute  rounded-md text-black justify-center flex items-center heading-2 font-bold text-opacity-100" style="height: 88px;width: 140px;background: #fcfcfcc7;"><i class="fas fa-check-circle text-error"></i></div>
                        <!-- <div class="absolute bottom-2 opacity-80 text-white text-center heading-6 bg-gray4 pl-1" style="width: 130px;">{{attach.fileSize | getFileSize}}</div> -->
                      </div>
                      <div class="text-center heading-6">{{attach.fileSize | getFileSize}}</div>
                      <div class="text-center heading-7">{{attach.uploadedOn | dateTimeWithName}}</div>
                    </div>
                   </div>
            </div>
        </div>
        <div class="layout my-2" v-if="organizationList.length === 0">
          <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
              <p class=" font-medium heading-4">No record found.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Pagination
          :totalDataCount="totalCount"
          :resetPagination="resetPaginationValues"
          :defaultPerPage="100"
          @callApiAsPerPagination="getCustomerDataFromPagination"/>
      </div>
      <div v-if="shoFullImage">
        <AttachmentPreview :currentlyLoadAllAttachments="allAttachmentsArray" :pathPrefix="pathPrefix" :selectedData="selectedData"/>
      </div>
    </div>
    <div v-if="removeConfirm" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'No'" @buttonAction="removeConfirm = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Yes'" @buttonAction="deleteAttach(null)"/>
        </div>
      </div>
    </div>
  </div>
  </template>
  <script>
  import Button from '@/View/components/globalButton.vue'
  import AttachmentPreview from './attachmentPreview.vue'
  import ADMINAPI from '@/View/Admin/api/Admin.js'
  // import Pagination from '@/View/components/pagination.vue'
  import Pagination from '@/View/components/pagination.vue'
  import deboucneMixin from '@/mixins/debounce.js'
  export default {
    components: {
      Pagination,
      Button,
      AttachmentPreview
    },
    mixins: [deboucneMixin],
    data () {
      return {
        array: [
          {
            title: 'All',
            isSel: true,
          },
          {
            title: 'Images',
            isSel: true,
          },
          {
            title: 'Videos',
            isSel: true,
          },
          {
            title: 'Others',
            isSel: true,
          },
        ],
        attTypeArray: [],
        mainFullArray: [],
        removeConfirm: false,
        requestType: '',
        totalCount: 0,
        isManualSelect: false,
        tempSelArr: [],
        isSelectAll: false,
        allAttachmentsArray: [],
        selectedData: {},
        pathPrefix: '',
        cardHeight: window.innerHeight - 260,
        cardHeight2: window.innerHeight - 300,
        isCardView: true,
        showInActive: false,
        mobileView: false,
        searchLoader: false,
        resetPaginationValues: false,
        searchForCustomer: '',
        titleObj: [
          {title: 'Size', icon: '', sortName: 'fileSize', sortVal: '', isSel: false},
          {title: 'Date', icon: 'fas fa-sort-amount-down', sortName: 'uploadedOn', sortVal: 'DESC', isSel: true},
          {title: 'Organization', icon: '', sortName: 'orgName', sortVal: '', isSel: false},
      ],
        filterObject: {
          start: 0,
          length: 100,
          searchVal: '',
          sortBy: 'uploadedOn',
          sortOrder: 'DESC',
          isActive: false,
          attTypeSel: '',
          orgId: 0,
        },
        paginationLength: 1,
        filterCol: 'text-gray-400',
        page: 1,
        shoFullImage: false,
        items: [20, 25, 30, 50, 100],
        limitResData: 100,
        paginationData: 100,
        organizationList: [],
      }
    },
    created() {
      window.addEventListener("resize", this.resizeWindowHandler);
    },
    mounted () {
      this.$root.$on('fullImageHandler', (data) => {
        if (data) {
          this.getEmployeeList()
        }
        this.shoFullImage = false
        })
      let query = this.$route.query.fromorgid
      this.filterObject.orgId = query !== null ? query : 0
      this.resizeWindowHandler();
      document.title = 'Attachment List'
      this.getContactListDeboucne = this.debounce(function () {
        if (this.searchForCustomer.length > 2) {
          this.resetPaginationValues = true
          this.searchLoader = true
        }
        console.log('debounde')
      }, 500)
      this.getEmployeeList()
      this.getAttList()
    },
    watch: {
      searchForCustomer: {
        handler () {
          if (this.searchForCustomer === null) {
            this.searchForCustomer = ''
          }
          if (this.searchForCustomer === '') {
            this.resetPaginationValues = true
            this.getEmployeeList()
          }
        }
      },
      titleObj: {
        handler () {
          console.log('titleObj 123', this.titleObj)
        }
      },
      paginationData: {
        handler () {
          this.filterObject.length = this.paginationData
          this.showlistLoader = true
          this.getEmployeeList(this.showlistLoader)
        },
        deep: true
      }
    },
    methods: {
        showFullImage (data, index) {
          if (this.isManualSelect) {
            if (!this.organizationList[index].isSelected) {
              this.organizationList[index].isSelected = true
              this.tempSelArr.push(this.organizationList[index])
            } else {
              this.organizationList[index].isSelected = false
              let fIndex = this.tempSelArr.findIndex(x => x.fileVaultId === data.fileVaultId)
              if (fIndex >= 0) {
                this.tempSelArr.splice(fIndex, 1)
              }
            }
            let count = 0
            this.organizationList.forEach(element => {
              if (element.isSelected) {
                count ++
              } else {
                count --
              }
            })
            if (count === this.tempSelArr.length) {
              this.isSelectAll = true
            } else {
              this.isSelectAll = false
            }
          } else {
            this.allAttachmentsArray = []
            this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
            if (this.organizationList.length > 0 ) {
                this.organizationList.forEach(element => {
                    this.allAttachmentsArray.push(element)
                })
            }
            setTimeout(() => {
            this.selectedData = data
            this.shoFullImage = true
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            }, 1000)
          }
        },
      resizeWindowHandler() {
        if (window.innerWidth < 684) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      },
      clearSearch () {
        this.searchForCustomer = ''
        this.searchLoader = false
        this.getEmployeeList()
      },
      filterListing(from) {
        this.resetPaginationValues = true
        this.array.forEach(item => {
          if (from.title === 'All') {
              item.isSel = true;
          } else {
            if (item.title === from.title) {
                item.isSel = true;
            } else {
                item.isSel = false;
            }
          }
      });
        if (from.title === 'Images') {
            this.filterObject.attTypeSel = this.attTypeArray.imageTypes;
        } else if (from.title === 'Videos') {
            this.filterObject.attTypeSel = this.attTypeArray.videoTypes;
        } else if (from.title === 'Others') {
            this.filterObject.attTypeSel = this.attTypeArray.otherTypes;
        } else if (from.title === 'All') {
            this.filterObject.attTypeSel = '';
        }
        this.getEmployeeList()
    },
      toggleSel () {
        this.isManualSelect = !this.isManualSelect
        if (!this.isManualSelect) {
          this.isSelectAll = false
          this.tempSelArr = []
          this.organizationList.forEach(element => {
            element.isSelected = false
          });
        }
      },
      clearSelection () {
        this.tempSelArr = []
          // this.isManualSelect = false
          this.isSelectAll = false
          this.organizationList.forEach(element => {
            element.isSelected = false
          });
        },
        checkboxCheck () {
          this.tempSelArr = []
          this.isSelectAll = !this.isSelectAll
          if (this.isSelectAll) {
            this.organizationList.forEach(element => {
              element.isSelected = true
              this.tempSelArr.push(element)
            });
          } else {
            this.organizationList.forEach(element => {
              element.isSelected = false
            });
            this.tempSelArr = []
          }
        },
        getCustomerDataFromPagination (data) {
        this.tempSelArr = []
        this.isManualSelect = false
        this.isSelectAll = false
          this.filterObject.start = data.offset
          this.filterObject.length = data.limit
          let showlistLoader = false
          if (data.offset !== 0) {
            showlistLoader = true
          }
          this.getEmployeeList(showlistLoader)
        },
        deleteAttach (data) {
          this.removeConfirm = false
          let deletedArr = []
          if (data !== null) {
            deletedArr.push(data.fileVaultId)
          } else {
            if (!this.isSelectAll) {
              this.tempSelArr.forEach((a) => {
                deletedArr.push(a.fileVaultId)
              })
            }
          }
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.DeleteAttachment(
            deletedArr,
            [],
            this.isSelectAll,
            this.requestType,
            response => {
              this.getEmployeeList()
              this.tempSelArr = []
              // this.isManualSelect = false
              this.isSelectAll = false
              this.organizationList.forEach(element => {
                element.isSelected = false
              });
              this.isManualSelect = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
            },
            (err) => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', { showAlert: true, message: err.message, color: 'error' })
            }
          )
        },
      sortByColumn (index) {
        for (let i = 0; i < this.titleObj.length; i++) {
          if (i === index) {
            console.log('this.titleObj[i].icon', this.titleObj[i].icon)
            console.log('this.titleObj[i].sortVal', this.titleObj[i].sortVal)
            console.log('this.filterObject.sortOrder', this.filterObject.sortOrder)
            if (this.titleObj[i].icon === '') {
              this.titleObj[i].icon = 'fas fa-sort-amount-down'
              this.titleObj[i].sortVal = 'DESC'
              this.filterObject.sortOrder = 'DESC'
              this.titleObj[i].isSel = true
            } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
              this.titleObj[i].icon = 'fas fa-sort-amount-up'
              this.titleObj[i].sortVal = 'ASC'
              this.filterObject.sortOrder = 'ASC'
              this.titleObj[i].isSel = true
            } else {
              this.titleObj[i].icon = 'fas fa-sort-amount-down'
              this.filterObject.sortOrder = 'DESC'
              this.titleObj[i].sortVal = 'DESC'
              this.titleObj[i].isSel = true
            }
        } else {
            this.titleObj[i].icon = ''
            this.titleObj[i].sortVal = ''
            this.titleObj[i].isSel = false
          }
        }
        this.filterObject.sortBy = this.titleObj[index].sortName
        this.getEmployeeList(true)
      },
      toTop () {
        this.$vuetify.goTo(0)
      },
      routingToDetail (data) {
        this.$router.push({name: 'SupportDetail', params: {supid: data.supportId}})
      },
      getSourceListArray (data) {
        this.showInActive = data
        this.filterObject.isActive = data
        this.getEmployeeList()
      },
      getLeaveDataAsPerPagination1 (data) {
        this.filterObject.length = data
        this.getEmployeeList()
      },
      getEmployeeList () {
        if (this.searchForCustomer === '') {
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        }
        ADMINAPI.GetAllAttachmentList(
          this.filterObject.orgId,
          this.filterObject.start,
          this.filterObject.length,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          this.filterObject.attTypeSel,
          response => {
            console.log("response", response.Data)
            this.resetPaginationValues = false
            let templist = response.Data.attachmentList === null ? [] : response.Data.attachmentList
            for (let index = 0; index < templist.length; index++) {
                let temp = templist[index].fileType.split('/')
                if (temp[0] === 'image' ) {
                templist[index].docType = 'image'
                } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                templist[index].docType = 'pdf'
                } else if (temp[0] === 'video' ) {
                templist[index].docType = 'video'
                }
                templist[index].isSelected = false
            }
            if (this.start >= 100) {
              this.requestType = 'other'
            } else {
              this.requestType = 'topLarge'
            }
            this.organizationList = templist
            this.mainFullArray = templist
            this.totalCount = response.Data.count
            this.pathPrefix = response.Data.pathPrefix
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      },
      getAttList () {
        ADMINAPI.GetAttachmentType(
          response => {
            console.log("response", response.Data)
            this.attTypeArray = response.Data === null ? [] : response.Data
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    beforeDestroy () {
    }
  }
  </script>
  <style scoped>
  .table_containder {
    padding: 1px;
  }
  .name_col {
    min-width: 560px !important;
    width: 560px !important;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .name_col1 {
    min-width: 260px !important;
    width: 260px !important;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .search_customer_box {
    min-width: 335px !important;
    max-width: 335px !important;
  }
  
  .progress-bar {
    height: 2px;
    background-color: rgb(18,149,186);
    width: 100%;
    overflow: hidden;
  }
  
  .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(121, 200, 221);
    animation: indeterminateAnimation 2s infinite linear;
    transform-origin: 0% 50%;
  }
  
  @keyframes indeterminateAnimation {
    0% {
      transform:  translateX(0) scaleX(0);
    }
    40% {
      transform:  translateX(0) scaleX(0.4);
    }
    100% {
      transform:  translateX(100%) scaleX(0.5);
    }
  }
  ::placeholder {
    font-size: 16px;
    color: #494949;
  }
  .list_btn {
    min-width: 77px !important;
    max-width: 77px;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .attachment-card {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }
  .scroll-img-view{
    height: 104px;
  }
  .zIndex {
    z-index: 100 !important;
  }
  </style>
  