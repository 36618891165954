<template>
    <div class="popup_overlay">
    <div class="custom_dialog" id="screen_view_section" :style="`width: 100%; overflow: auto; height:100vh;`">
      <div class="">
        <div>
          <div class="bg-primary flex justify-between p-2.5">
            <div class="font-medium text-white heading-3 pl-1">
              {{selectFileDataObj.orgName}} <span class="heading-5" v-if="selectFileDataObj.entitySlug !== ''">({{selectFileDataObj.entitySlug | capitalizeFirstLetter}})</span>
            </div>
            <div class="flex items-center">
              <div class=" text-white heading-3 cursor-pointer bg-error px-3 py-1 rounded-xl ml-3" @click="delteAddressConfirm=true">
                <i class="fas fa-trash-alt"></i>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-2.5 py-1 rounded-xl ml-3" @click="downLoadDocument123(selectFileDataObj.fileVaultId, selectFileDataObj.displayFileName)">
                <i class="fas fa-download"></i>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-2.5 py-1 rounded-xl ml-3" @click="discard(false)">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="flex items-center mb-4 image-show-container" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`">
            <div class=" font-medium text-text2 heading-4 pl-1 ">
              <div class="loader ease-linear ml-2"></div>
            </div>
          </div>
          <div v-if="loading">
            <div v-if="selectFileDataObj.docType === 'image' && !isSHowErr" class="p-2" style="display: flex;justify-content: center;align-items: center;">
              <img :style="`width: auto;height:` + imageDivHeight2 + `px;`" class="" :src="srcUrl" alt="">
            </div>
            <div v-if="selectFileDataObj.docType === 'video' && !isSHowErr" class="flex items-center mb-4">
              <video id="myVideo" autoplay class="mt-1" controls :style="`width: 100%;height:` + imageDivHeight2 + `px;`">
              <source  type="video/mp4" align="center" justify="center" class="ma-0" :src="srcUrl">
              </video>
            </div>
            <div v-if="selectFileDataObj.docType === 'pdf' && !isSHowErr" class="p-2" type="application/pdf">
              <iframe class="pdfIframe" :src="srcUrl" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`" />
            </div>
            <div v-if="(selectFileDataObj.docType !== 'image' && selectFileDataObj.docType !== 'video' && selectFileDataObj.docType !== 'pdf') || isSHowErr" class="flex items-center mb-4 image-show-container justify-center" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`">
              <div class=" font-medium text-text2 heading-4 pl-1 ">
                  No Preview Available
              </div>
            </div>
          </div>
          <div>
             <div class="font-bold heading-4">
                <div class="flex items-center justify-center">
                  <p class="">{{selectFileDataObj.displayFileName ? selectFileDataObj.displayFileName : 'Attachment'}}</p>
                  <p class="p-0 m-0 ml-2 text-gray4 heading-5">{{selectFileDataObj.fileSize | getFileSize}}</p>
                  <p class="p-0 m-0 mx-2 text-gray4 heading-5">({{selectFileDataObj.uploadedOn | dateTimeWithName}})</p>
                    <!-- <p>{{currentAttachmentIndex === 0}} // {{currentAttachmentIndex === currentlyLoadAllAttachments.length - 1}}</p> -->
                    <button :disabled="currentAttachmentIndex === 0 ? true : false" :class="currentAttachmentIndex === 0 ? 'text-gray4' : 'text-primary cursor-pointer'" @click="previousAttachmentView" class="mx-1 mt-1"><i class="fa-solid fa-circle-chevron-left " style="font-size: 28px;"></i></button>
                    <button :disabled="currentAttachmentIndex === currentlyLoadAllAttachments.length - 1 ? true : false" :class="currentAttachmentIndex === currentlyLoadAllAttachments.length - 1 ? 'text-gray4' : 'text-primary cursor-pointer'" @click="nextAttachmentView" class="mx-1 mt-1"><i class="fa-solid fa-circle-chevron-right" style="font-size: 28px;"></i></button>
                </div>
            </div>
          </div>
          <div >
            <div class="imagesContainer pt-2" id="horizontalImages">
                <div class="attachment-card cursor-pointer"   v-for="(attach, attachmentIndex) in currentlyLoadAllAttachments" :key="attachmentIndex" :id="attach.fileVaultId" @click="onAttachmentClick(attach)">
                    <!-- Image -->
                    <div class="mx-1" v-if="attach.docType === 'image'" :style="selectFileDataObj.fileVaultId === attach.fileVaultId ? borderStyle : ``" style="display: flex;justify-content: center;align-items: center;width: max-content;border: 2px solid #a9a9aa;padding: 5px;">
                        <img @error="handleImageError"  :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" style="width: 120px;height: 90px;object-fit: contain;">
                    </div>
                    <!-- Video -->
                    <div class="mx-1" v-if="attach.docType === 'video'" :style="selectFileDataObj.fileVaultId === attach.fileVaultId ? borderStyle : ``" style="display: flex;justify-content: center;align-items: center;width: max-content;border: 2px solid #a9a9aa;padding: 5px;">
                        <img @error="handleImageError" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" style="width: 120px;height: 90px;object-fit: contain;">
                        <div class="" style="display: flex;position: absolute;justify-content: center;align-items: center;">
                            <i class="fas fa-play-circle text-primary" style="font-size:24px;"></i></div>
                        </div>
                    <!-- PDF -->
                    <div class="mx-1" v-if="attach.docType !== 'image' && attach.docType !== 'video'" :style="selectFileDataObj.fileVaultId === attach.fileVaultId ? borderStyle : ``" style="display: flex;justify-content: center;align-items: center;width: max-content;border: 2px solid #a9a9aa;padding: 5px;">
                        <img src="@/assets/images/svg/file-lines-solid.svg" style="width: 120px;height: 90px;object-fit: contain;">
                    </div>
                </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div v-if="delteAddressConfirm" class="popup_overlay relative px-4">
        <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-5 font-bold">
            <p class="heading-3 text-text1">Are you sure?</p>
          </div>
          <div  class="mb-5 font-bold">
            <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="delteAddressConfirm = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteAddress()"/>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import Button from '@/View/components/globalButton.vue'
import * as config from '@/config.js'
import {downloadFile, checkFileTypeForPreview} from '@/utils/common.js'
import axios from 'axios'
export default {
  components: {
    Button
  },
  props: ["currentlyLoadAllAttachments", "pathPrefix", "selectedData"],
  data () {
    return {
    delteAddressConfirm: false,
     borderStyle: {
        background: '#a08787'
      },
      loading: false,
      isSHowErr: false,
      baseUrl: config.API_ROOT,
      srcUrl: '',
      imageDivHeight2: window.innerHeight - 240,
      selectFileDataObj: {
        fileVaultId: 0,
        entityId: 0,
        entityDetailId: 0,
        noteId: 0,
        entitySlug: '',
        displayFileName: '',
        systemGeneratedFileName: '',
        fileType: '',
        fileSize: 0,
        uploadedOn: '',
        thumbPath: '',
        docType: '',
      },
      currentAttachmentIndex: 0
    }
  },
  created() {
  },
  mounted () {
    this.selectFileDataObj = this.selectedData
    console.log('selectedData', this.selectedData)
    console.log('currentlyLoadAllAttachments', this.currentlyLoadAllAttachments)
    let index = this.currentlyLoadAllAttachments.findIndex(attach => attach.fileVaultId === this.selectedData.fileVaultId);
    if (index !== -1) {
        this.currentAttachmentIndex = index;
    }

    this.$root.$on('confirmBoxHandler', (response) => {
      if (response) {
        this.discard(true)
      }
      document.body.style = 'overflow: visible;'
      this.deleteAttch = false
    })
    this.downLoadDocument(this.selectedData.fileVaultId, this.selectedData.docType)
    console.log('srcUrl', this.srcUrl);
    document.body.style = 'overflow: hidden;'
    setTimeout(() => {
       this.scrollToTop(this.selectedData.fileVaultId) 
    }, 2000);
  },
  watch: {},
  methods: {
    deleteAddress () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.DeleteAttachment(
        this.selectFileDataObj.fileVaultId,
        [],
        false,
        'topLarge',
        response => {
          this.$root.$emit('fullImageHandler', true)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.isLoading = true
          this.buttonAction = false
        },
        error => {
          this.isLoading = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    nextAttachmentView() {
        if (this.currentAttachmentIndex !== this.currentlyLoadAllAttachments.length - 1) {
            this.loading = false
            let data = this.currentlyLoadAllAttachments[this.currentAttachmentIndex + 1]
            console.log('data-nextAttachmentView', data);
            setTimeout(() => {
                this.srcUrl = ''
                this.selectFileDataObj = data
                this.downLoadDocument(data.fileVaultId, data.docType)
                this.scrollToTop(data.fileVaultId)
                let index = this.currentlyLoadAllAttachments.findIndex(attach => attach.fileVaultId === data.fileVaultId);
                if (index !== -1) {
                    this.currentAttachmentIndex = index;
                }
            }, 500);
        }
    },
    previousAttachmentView() {
        if (this.currentAttachmentIndex !== 0) {
            this.loading = false
            let data = this.currentlyLoadAllAttachments[this.currentAttachmentIndex - 1]
            console.log('data-previousAttachmentView', data);
            setTimeout(() => {
                this.srcUrl = ''
                this.selectFileDataObj = data
                this.downLoadDocument(data.fileVaultId, data.docType)
                this.scrollToTop(data.fileVaultId)
                let index = this.currentlyLoadAllAttachments.findIndex(attach => attach.fileVaultId === data.fileVaultId);
                if (index !== -1) {
                    this.currentAttachmentIndex = index;
                }
            }, 500);
        }
    },
    handleImageError(event) {
        console.log('event', event);
        // event.target.style.display = 'none'; // Hide the image
        event.target.src = require('@/assets/images/video-white-thumbnail.png');
    },
    discard (data) {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('fullImageHandler', data)
    },
    onAttachmentClick (data) {
        console.log('data--213124', data)
        this.loading = false
        this.srcUrl = ''
        this.selectFileDataObj = data
        this.downLoadDocument(data.fileVaultId, data.docType)
        let index = this.currentlyLoadAllAttachments.findIndex(attach => attach.fileVaultId === data.fileVaultId);
        if (index !== -1) {
            this.currentAttachmentIndex = index;
        }
        this.scrollToTop(data.fileVaultId) 
    },
    async downLoadDocument123 (fileVaultId, fileObject) {
      let url = this.$store.getters.liveServerUrl + 'api/v1/superadmin/organization/downloadAttachment'
      let formData = new FormData()
      formData.append('fileVaultId', fileVaultId)
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      this.$store.dispatch('CancelDownloadRequest', source)
      this.$store.dispatch('SetDownloadLoader', true)
      let response = await downloadFile(formData, url, source)
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileObject)
        document.body.appendChild(link)
        link.click()
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    async downLoadDocument (fileVaultId, selectedType) {
        let url = this.baseUrl + 'api/v1/superadmin/organization/downloadAttachment'
        let formData = new FormData()
        formData.append('fileVaultId', fileVaultId)
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        this.$store.dispatch('CancelDownloadRequest', source)
        let response = await downloadFile(formData, url, source)
        if (response.status === 200) {
          this.$store.dispatch('CancelDownloadRequest', null)
          if (checkFileTypeForPreview(response.headers['content-type'])) {
            this.srcUrl = URL.createObjectURL(response.data)
          }
          this.$store.dispatch('SetDownloadLoader', false)
          this.$store.dispatch('SetDownloadMessage', '')
          this.loading = true
          setTimeout(() => {
            if (selectedType === 'video') {
                document.getElementById('myVideo').load()
            }
        }, 200)
        } else {
          this.isSHowErr = true
          this.loading = true
          this.$store.dispatch('CancelDownloadRequest', null)
          this.$store.dispatch('SetDownloadMessage', '')
          this.$store.dispatch('SetDownloadLoader', false)
        }
      },
      scrollToTop (id) {
      setTimeout(() => {
        var horizontal = document.getElementById('horizontalImages')
        var elmnt = document.getElementById(id)
        console.log('elmnt----->>', elmnt)
        horizontal.scrollTo({
          top: 0,
          left: elmnt.offsetLeft - 200,
          behavior: 'smooth'
        })
      }, 100)
    }
  },
  beforeDestroy () {
    this.$root.$off("confirmBoxHandler");
  }
}
</script>
<style scoped>
.imageFullScreen {
  width: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}
.image-show-container {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  .imagesContainer {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-y: hidden;
  margin: 0 auto;
}

.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
}
</style>